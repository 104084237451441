@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.portfolio {
  padding: 32px 0 0;
  // // padding: 50px 0 100px;
  // padding: 32px 0;

  // @include breakpoint-min(768px) {
  //   padding: 20px 0;
  // }
  @include breakpoint-min(1200px) {
    padding: 40px 0;
  }
}

.portfolio__title-wrap {
  @extend %flex-ac-jsb;
  margin-bottom: 26px;

  @include breakpoint-min(1200px) {
    margin-bottom: 56px;
  }
  // @include breakpoint-min(1920px) {
  //   margin-bottom: 56px;
  // }
}
.portfolio__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;

  @include breakpoint-min(768px) {
    font-size: 24px;
    line-height: normal;
  }
  @include breakpoint-min(1200px) {
    font-size: 40px;
    line-height: normal;
  }
  @include breakpoint-min(1920px) {
    font-size: 44px;
    line-height: normal;
  }

  // @include breakpoint-min(1200px) {
  //   font-size: 65px;
  //   line-height: normal;
  // }
  // @include breakpoint-min(1920px) {
  //   font-size: 65px;
  //   line-height: normal;
  // }
}
.portfolio__link {
  color: var(--color--white, #fff);
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @include breakpoint-min(768px) {
    font-size: 22px;
    line-height: normal;
  }
  @include breakpoint-min(1200px) {
    font-size: 36px;
    line-height: normal;
  }
  @include breakpoint-min(1920px) {
    font-size: 42px;
    line-height: normal;
  }

  // @include breakpoint-min(1200px) {
  //   font-size: 48px;
  //   font-weight: 400;
  // }
  // @include breakpoint-min(1920px) {
  //   font-size: 48px;
  //   font-weight: 400;
  // }
}

.portfolio__list {
  @extend %flex-dc;
  width: 100%;
}

@import '/src/scss/utils/placeholders';

.projects {
  padding: 32px 0;
}

.projects-category__list {
  @extend %flex-dc;
  gap: 18px;
  width: 100%;
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.logo {
  flex-shrink: 0;
  @extend %flex-ac;
  width: 48px;
  height: 26px;

  color: var(--color--white, #ffffff);

  & svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }

  @include breakpoint-min(1200px) {
    width: 108px;
    height: 68px;
  }
  // @include breakpoint-min(1920px) {
  //   width: 108px;
  //   height: 68px;
  // }
}

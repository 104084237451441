@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.lang {
  position: relative;

  flex-shrink: 0;
  @extend %flex-ac;
  margin-left: auto;
  min-height: 30px;
  z-index: 3;
}

.lang__btn {
  @extend %flex-ac-jc;
  gap: 4px;
  height: 100%;

  color: var(--color--white, #fff);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-transform: uppercase;

  @extend %transition--100;

  & svg {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    @extend %transition--100;
  }

  .active & svg {
    transform: rotate(180deg);
  }

  @include breakpoint-min(1200px) {
    gap: 6px;
    font-size: 22px;

    & svg {
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
    }
  }
  // @include breakpoint-min(1920px) {
  //   gap: 6px;
  //   font-size: 22px;

  //   & svg {
  //     flex: 0 0 30px;
  //     width: 30px;
  //     height: 30px;
  //   }
  // }
}

.lang__list {
  position: absolute;
  left: 0;
  top: calc(100% + 4px);

  @extend %flex-dc;
  gap: 4px;

  @extend %transition--100;
  
  .lang:not(.active) & {
    pointer-events: none;
    visibility: none;
    opacity: 0;
  }
}

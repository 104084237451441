@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.project-swiper-gallery__wrap {
  @extend %flex-dc;
  gap: 12px;

  @extend %transition--300;

  @include breakpoint-min(1200px) {
    flex-direction: row;
    height: auto;
    max-height: auto;
  }
}

.project-swiper-gallery {
  width: 100%;
  margin: 0;

  background-color: transparent;
  // @extend %transition--200;

  .project-swiper-item {
    @extend %img-wh100-ofc;
    height: auto;

    border-radius: 12px;
    @extend %transition--300;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    height: 28px;

    --swiper-navigation-sides-offset: 0px;
    border-radius: 4px;
    background-color: rgba($color: #000, $alpha: 0.5);
  }
  .swiper-button-prev {
    transform: rotate(90deg);
  }
  .swiper-button-next {
    transform: rotate(-90deg);
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    width: 100%;
    height: 100%;

    background-image: url('../../../public/assets/images/icons/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 44px;
  }

  @include breakpoint-min(1200px) {
    .project-swiper-gallery {
      height: fit-content;
    }

    .swiper-button-prev {
      top: 28px;
      bottom: initial;
      left: 50%;

      width: 44px;
      height: 28px;

      transform: rotate(180deg);
    }
    .swiper-button-next {
      top: initial;
      bottom: 8px;
      left: 50%;

      width: 44px;
      height: 28px;
      transform: rotate(0deg);
    }
  }
}

.project-swiper-gallery-thumbs {
  margin: 0;

  background-color: transparent;
  // @extend %transition--200;

  .project-swiper-item {
    @extend %img-wh100-ofc;
    height: 100%;

    border-radius: 12px;
    @extend %transition--300;
    @extend %pointer;
  }

  @include breakpoint-max(1200px - 1px) {
    height: 100px;
  }

  @include breakpoint-min(1200px) {
    flex: 0 0 200px;
    width: 200px;
  }

  @include breakpoint-min(1440px) {
    flex: 0 0 280px;
    width: 280px;
  }
  @include breakpoint-min(1920px) {
    flex: 0 0 400px;
    width: 400px;
  }
}

.pswp__img {
  height: auto !important;
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.review__rating {
  @extend %flex-ac;
  width: fit-content;
  margin-bottom: 6px;

  & svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    stroke: #2dbc55;
    stroke-width: 1px;
  }

  .rating__star {
    fill: transparent; /* Прозорі зірки */
  }
  .rating__star--filled {
    fill: #2dbc55; /* Заповнені зірки */
  }
  .rating__star--partial {
    fill: url(#dynamicGradient); /* Динамічний градієнт для частково заповнених зірок */
  }

  @include breakpoint-min(1200px) {
    margin-bottom: 8px;

    & svg {
      width: 32px;
      height: 32px;
    }
  }
}


@mixin breakpoint-min($bp) {
  @media (min-width: $bp) {
    @content;
  }
}

@mixin breakpoint-max($bp) {
  @media (max-width: $bp) {
    @content;
  }
}

@mixin breakpoint-min-max($bp1, $bp2) {
  @media (min-width: $bp1) and (max-width: $bp2) {
    @content;
  }
}

@mixin breakpoint-min-width-height($bp1, $bp2) {
  @media (min-width: $bp1) and (min-height: $bp2) {
    @content;
  }
}

// ==================================================
// @include breakpoint-min($mobile) {aaa}
// @include breakpoint-min($tablet) {aaa}
// @include breakpoint-min($desktop) {aaa}

// @include breakpoint-max($extraSmallMax) {aaa}
// @include breakpoint-max($mobileMax) {aaa}
// @include breakpoint-max($tabletMax) {aaa}

// @include breakpoint-min-max($tablet, $tabletMax) {aaa}
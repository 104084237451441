@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.burger-menu__nav {
  .nav__list {
    @extend %flex-dc;
    align-items: flex-end;
    gap: 24px;

    @include breakpoint-min(1200px) {
      gap: 36px;
    }
  }

  .nav__item {
    @extend %transition--400;

    .burger-menu.isHidden & {
      opacity: 0;
      &:nth-of-type(1) {
        transform: translateX(250%) translateY(-200px);
      }
      &:nth-of-type(2) {
        transform: translateX(250%);
      }
      &:nth-of-type(3) {
        transform: translateX(250%) translateY(200px);
      }
    }
  }

  .nav__link {
    position: relative;

    display: flex;
    align-items: baseline;
    gap: 12px;

    color: var(--color--white, #fff);
    text-align: right;

    font-size: 32px;
    font-weight: 600;
    pointer-events: initial;

    @extend %transition--300;

    &::before {
      content: '0' attr(data-idx) '/';

      color: #808080;
      text-align: right;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
    }

    &::after {
      content: '';

      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      width: 0px;
      height: 2px;

      background-color: #fff;
      @extend %transition--300;
    }

    &:hover {
      padding-right: 32px;

      &::after {
        width: 20px;
      }
    }

    @include breakpoint-min(1200px) {
      gap: 24px;
      font-size: 80px;
      line-height: 86px;

      &::before {
        font-size: 40px;
      }

      &:hover {
        padding-right: 64px;

        &::after {
          width: 40px;
        }
      }
    }
  }
}

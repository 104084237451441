@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.modal {
  @extend %fixed;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;
  max-height: 100vh;

  z-index: 3;
  // overflow-y: scroll;

  &.isHidden {
    pointer-events: none;
    visibility: hidden;
  }
}

.modal__container.container {
  @extend %flex-dc;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;

  overflow-y: scroll;
}
@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.burger-menu {
  @extend %fixed;
  top: var(--header-height);
  right: 0;

  width: 100%;
  height: 100%;
  max-height: 100vh;

  z-index: 3;

  &.isHidden {
    pointer-events: none;
    visibility: hidden;
  }
}

.burger-menu__container {
  @extend %flex-jc-dc;
  width: 100%;
  height: 100%;
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.project-swiper-demo {
  height: 170px;
  padding-bottom: 20px;

  background-color: transparent;

  .swiper-slide {
    height: 150px;
  }
  .project-swiper-item {
    width: 100%;
    height: 100%;

    object-fit: cover;
    border-radius: 12px;
    @extend %transition--300;
  }
  .swiper-slide-prev .project-swiper-item,
  .swiper-slide-next .project-swiper-item {
    width: 88%;
    height: 125px;
    transform: translateY(12px);
  }
  .swiper-slide-prev .project-swiper-item {
    transform: translateX(14%) translateY(12px);
  }

  .swiper-pagination {
    --swiper-pagination-bottom: 0;
    @extend %flex-ac-jc;
    gap: 8px;
  }
  .swiper-pagination-bullet {
    --swiper-pagination-bullet-horizontal-gap: 0;
    --swiper-pagination-bullet-inactive-opacity: 1;

    width: 8px;
    height: 8px;

    background-color: #3e3e3e;

    &-active {
      background-color: #d9d9d9;
    }
  }

  // @include breakpoint-min(768px) {
  //   height: 200px;
  //   // padding-bottom: 20px;
  // }
  @include breakpoint-min(1200px) {
    height: 352px;
    padding-bottom: 52px;

    .swiper-slide {
      height: 300px;
    }
    .swiper-slide-prev .project-swiper-item,
    .swiper-slide-next .project-swiper-item {
      height: 250px;
      transform: translateY(25px);
    }
    .swiper-slide-prev .project-swiper-item {
      transform: translateX(14%) translateY(25px);
    }

    .swiper-pagination {
      gap: 24px;
    }
    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
    }
  }
}


.pswp__img {
  height: auto !important;
}
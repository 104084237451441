@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.review {
  position: relative;

  @extend %flex-dc;
  gap: 6px;
  padding: 20px;

  border-radius: 16px;
  background: var(--neutral-500, #181818);
  border: 1px solid transparent;

  @include breakpoint-min(1200px) {
    gap: 10px;
    padding: 32px;
  }

  &[aria-disabled='true'] {
    cursor: auto;
    pointer-events: none;
  }
}

.review--demo {
  transition: 600ms ease;

  @include breakpoint-min(1200px) {
    &:hover {
      border-radius: 16.95px;
      border: 1px solid var(--secondary, #1b6a31);
      background: var(--neutral-500, #181818);
      box-shadow: 0px 0px 25px 0px rgba(44, 184, 83, 0.5);
    }
  }
  // @include breakpoint-min(1920px) {
  //   &:hover {
  //     border-radius: 16.95px;
  //     border: 1px solid var(--secondary, #1b6a31);
  //     background: var(--neutral-500, #181818);
  //     box-shadow: 0px 0px 25px 0px rgba(44, 184, 83, 0.5);
  //   }
  // }
}

.review__icon--arrow {
  position: absolute;
  top: 20px;
  right: 20px;

  width: 24px;
  height: 24px;

  transition: 600ms ease;

  @include breakpoint-min(1200px) {
    top: 32px;
    right: 32px;

    width: 42px;
    height: 42px;

    .review--demo:hover & {
      transform: translate(10px, -10px);
    }
  }
  // @include breakpoint-min(1920px) {
  //   top: 32px;
  //   right: 32px;

  //   width: 42px;
  //   height: 42px;

  //   .review--demo:hover & {
  //     transform: translate(10px, -10px);
  //   }
  // }
}

.review__img {
  display: block;
  width: 40px;
  height: 40px;
  margin-bottom: 6px;

  object-fit: cover;

  border-radius: 50%;
  background-color: #808080;

  @include breakpoint-min(1200px) {
    width: 62px;
    height: 62px;
    margin-bottom: 8px;
  }
  // @include breakpoint-min(1920px) {
  //   width: 62px;
  //   height: 62px;
  //   margin-bottom: 8px;
  // }
}

.review__name {
  color: var(--color--white, #fff);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;

  @include breakpoint-min(1200px) {
    font-size: 26px;
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 26px;
  // }
}

.review__text {
  min-height: 100%;

  color: var(--neutral-200, #808080);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  transition: 600ms ease;

  @include breakpoint-min(1200px) {
    font-size: 22px;
    line-height: 140%; /* 30.8px */

    .review--demo:hover & {
      min-height: 120%;
      font-size: 28px;
    }
  }
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.hero {
  position: relative;
  padding: 24px 0 88px;
  @extend %flex-jc-dc;

  // width: 100%;
  // height: 100vh; /* висота на весь екран */
  // background-image: url('../../../public/assets/video/homepage.gif');
  // background-size: cover; /* адаптує зображення до розміру екрану */
  // background-position: center; /* вирівнює по центру */
  // background-repeat: no-repeat; /* щоб не повторювалося */


  &::before {
    content: '';

    position: absolute;
    top: -4px;
    left: 0;
    z-index: -1;

    display: block;
    width: 100%;
    height: 400px;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  }
  &::after {
    content: '';

    position: absolute;
    bottom: -4px;
    left: 0;
    z-index: -2;

    display: block;
    width: 100%;
    height: 100px;
    
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  }

  &.scrollSnap {
    padding: calc(24px + var(--header-height)) 0 88px;
  }

  @include breakpoint-min(1200px) {
    padding: calc(78px + var(--header-height)) 0 220px;

    &::before {
      height: 500px;
    }
    &::after {
      height: 360px;
    }
  }
}
.hero__video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__desc {
  @include breakpoint-min(768px) {
    max-width: 500px;
  }
  @include breakpoint-min(1200px) {
    max-width: 960px;
  }
  // @include breakpoint-min(1920px) {
  //   max-width: 740px;
  // }
}

.hero__title {
  margin-bottom: 18px;

  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    font-size: 70px;
    line-height: 86px;
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 70px;
  //   line-height: 86px;
  // }
}
.hero__text {
  margin-bottom: 28px;

  font-weight: 400;
  line-height: 20px;

  @include breakpoint-min(1200px) {
    font-size: 28px;
    line-height: 140%;
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 28px;
  //   line-height: 140%;
  // }
}

.hero-btn {
  @extend %flex-ac-jc;
  height: 44px;
  width: fit-content;

  color: var(--color--dark, #000);
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  & svg {
    fill: currentColor;
    transform: rotate(-135deg);
    @extend %transition--200;
  }
  & span {
    @extend %flex-ac-jc;
    height: 100%;

    @extend %transition--200;
    background-color: var(--color--white, #fff);
  }
  & .hero-btn__text {
    min-width: 148px;
    border-radius: 24px;
  }
  & .hero-btn__arrow {
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }

  &:focus-within {
    outline: none;
  }

  @include breakpoint-min(1200px) {
    height: 105px;
    font-size: 22px;
    border-radius: 500px;
    z-index: 2;

    & svg {
      width: 44px;
      height: 44px;
    }
    & span {
      position: relative;
      background-color: transparent;
      overflow: hidden;

      &::before {
        content: '';

        position: absolute;
        top: 50%;
        left: 0;
        z-index: -1;
        transform: translateY(-50%);

        width: calc(100% + 2px);
        height: calc(100% + 2px);
        max-width: calc(100% + 2px);
        max-height: calc(100% + 2px);

        background-color: var(--color--white, #fff);
      }
    }

    & .hero-btn__text {
      min-width: 346px;
      border-radius: 500px;

      &::before {
        border-radius: 500px;
        transition: all 200ms ease-out 0ms;
      }
    }
    & .hero-btn__arrow {
      &::before {
        border-radius: 50%;
        transition: all 100ms ease-out 190ms;
      }
    }

    &:hover {
      & .hero-btn__text {
        min-width: 420px;

        &::before {
          transition: all 200ms ease-out 90ms;
        }
      }
      & .hero-btn__arrow {
        &::before {
          transition: all 100ms ease-out 0ms;
        }
      }
      & svg {
        transform: rotate(45deg);
      }
      & span {
        color: #fff;
        border: 1px solid var(--color--white, #fff);

        &::before {
          max-width: 0;
          max-height: 20%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  // @include breakpoint-min(1920px) {
  //   height: 105px;
  //   font-size: 22px;
  //   border-radius: 500px;
  //   z-index: 2;

  //   & svg {
  //     width: 44px;
  //     height: 44px;
  //   }
  //   & span {
  //     position: relative;
  //     background-color: transparent;
  //     overflow: hidden;

  //     &::before {
  //       content: '';

  //       position: absolute;
  //       top: 50%;
  //       left: 0;
  //       z-index: -1;
  //       transform: translateY(-50%);

  //       width: calc(100% + 2px);
  //       height: calc(100% + 2px);
  //       max-width: calc(100% + 2px);
  //       max-height: calc(100% + 2px);

  //       background-color: var(--color--white, #fff);
  //     }
  //   }

  //   & .hero-btn__text {
  //     min-width: 346px;
  //     border-radius: 500px;

  //     &::before {
  //       border-radius: 500px;
  //       transition: all 200ms ease-out 0ms;
  //     }
  //   }
  //   & .hero-btn__arrow {
  //     &::before {
  //       border-radius: 50%;
  //       transition: all 100ms ease-out 190ms;
  //     }
  //   }

  //   &:hover {
  //     & .hero-btn__text {
  //       min-width: 420px;

  //       &::before {
  //         transition: all 200ms ease-out 90ms;
  //       }
  //     }
  //     & .hero-btn__arrow {
  //       &::before {
  //         transition: all 100ms ease-out 0ms;
  //       }
  //     }
  //     & svg {
  //       transform: rotate(45deg);
  //     }
  //     & span {
  //       color: #fff;
  //       border: 1px solid var(--color--white, #fff);

  //       &::before {
  //         max-width: 0;
  //         max-height: 20%;
  //         opacity: 0;
  //         transform: translate(-50%, -50%);
  //       }
  //     }
  //   }
  // }
}

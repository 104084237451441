@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.footer {
  position: relative;
  padding: 40px 0 200px;
  // padding: 40px 0 calc(60px + 328px);
  max-height: var(--window-height, 100vh);

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    display: block;
    width: 100%;
    height: 100%;
    
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  }
  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    display: block;
    width: 100%;
    height: 100%;
    
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  }

  @include breakpoint-min(1200px) {
    // padding: 52px 0 calc(52px + 654px);
    padding: 52px 0 250px;
  }
  @include breakpoint-min(1920px) {
    // padding: 52px 0 calc(52px + 654px);
    padding: 52px 0 300px;
  }
}

.footer__top {
  padding-bottom: 48px;
}

.footer__bottom {
  padding-top: 48px;
  border-top: 1px solid #fff;
}

.copyright {
  @extend %flex-dc;
  font-size: 18px;
  font-weight: 300;

  & span {
    text-transform: uppercase;
  }

  @include breakpoint-min(1200px) {
    font-size: 32px;
    font-weight: 400;
    line-height: 130%; /* 41.6px */
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 32px;
  //   font-weight: 400;
  //   line-height: 130%; /* 41.6px */
  // }
}

.footer__video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.benefits {
  @extend %flex-dc;
  padding: 12px 0;

  @include breakpoint-min(768px) {
    padding: 20px 0;
  }
  @include breakpoint-min(1200px) {
    padding: 40px 0;
  }
  @include breakpoint-min(1920px) {
    padding: 64px 0;
  }
}
.benefits__container {
  position: relative;
  @extend %flex-ac-jc;
  flex-grow: 1;
}
.benefits__title {
  position: absolute;
  top: 0;
  right: 16px;

  color: var(--neutral-400, #222);
  text-align: right;

  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  @include breakpoint-min(768px) {
    right: 32px;
  }
  @include breakpoint-min(1200px) {
    right: 80px;

    font-size: 36px;
    font-weight: 700;
  }
  @include breakpoint-min(1920px) {
    right: 120px;

    font-size: 48px;
    font-weight: 700;
  }
}

.benefit {
  position: relative;

  @extend %flex-ac-dc;
  gap: 2px;
  padding: 32% 12% 12%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  aspect-ratio: 1 / 1;

  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.5s ease-out;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    display: block;
    width: 100%;
    height: 100%;

    background-image: url('../../../public/assets/images/icons/benefit-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform-origin: center;
    background-size: 100%;
    transform: rotate(-90deg);
    transition: 500ms ease-in;
  }

  &.start { opacity: 1; }

  @include breakpoint-min(390px) {
    width: 358px;
    height: 358px;
    padding: 140px 48px;
  }
  @include breakpoint-min(768px) {
    gap: 4px;
    width: 500px;
    height: 500px;
    padding: 160px 80px;
  }
  @include breakpoint-min(1200px) {
    gap: 6px;
    width: 640px;
    height: 640px;
    padding: 200px 90px;
  }
  @include breakpoint-min(1920px) {
    // gap: 8px;
    width: 720px;
    height: 720px;
    padding: 220px 90px;
  }
}
.benefit__toggle {
  position: relative;
  z-index: 1;

  @extend %flex-ac;
  width: 44px;
  height: 24px;
  padding: 2px;
  margin-bottom: 12px;
  flex-shrink: 0;

  border-radius: 12px;
  border: 1px solid var(--neutral-400, #222);
  background: linear-gradient(270deg, #181818 26.77%, #7e7e7e 339.49%);
  --translateX: 20px;
  
  &::before {
    content: '';

    display: block;
    width: 18px;
    height: 18px;

    border-radius: 50%;
    background: linear-gradient( 239deg, rgba(184, 184, 184, 0.5) 24.8%, rgba(88, 88, 88, 0.5) 134.44% );
  }

  .benefit--1 &::before { animation: fadeInOut1 3s ease forwards 0.5s; } // Анімація з затримкою 0.5s
  .benefit--2 &::before { animation: fadeInOut2 3s ease forwards 0.5s; } // Анімація з затримкою 0.5s
  .benefit--3 &::before { animation: fadeInOut3 3s ease forwards 0.5s; } // Анімація з затримкою 0.5s
  .benefit--4 &::before { animation: fadeInOut4 3s ease forwards 0.5s; } // Анімація з затримкою 0.5s

  @include breakpoint-min(768px) {
    width: 64px;
    height: 32px;

    border-radius: 16px;
    --translateX: 32px;

    &::before {
      width: 26px;
      height: 26px;
    }
  }

  @include breakpoint-min(1200px) {
    width: 108px;
    height: 48px;
    padding: 4px;
    margin-bottom: 12px;

    border-radius: 64px;
    --translateX: 58px;

    &::before {
      width: 40px;
      height: 40px;
    }
  }
  @include breakpoint-min(1920px) {
    width: 124px;
    height: 64px;
    padding: 8px;
    // margin-bottom: 20px;

    border-radius: 64px;
    --translateX: 58px;

    &::before {
      width: 50px;
      height: 50px;
    }
  }
}
.benefit__title {
  position: relative;
  z-index: 1;

  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  transition: opacity 200ms 100ms ease-out;

  @include breakpoint-min(768px) {
    font-size: 22px;
    line-height: normal;
  }
  @include breakpoint-min(1200px) {
    font-size: 36px;
    line-height: normal;
  }
  @include breakpoint-min(1920px) {
    font-size: 42px;
    line-height: normal;
  }
}
.benefit__text {
  position: relative;
  z-index: 1;

  text-align: center;
  line-height: 20px;
  opacity: 0;

  .benefit--1 & { animation: fadeInOutText1 3s linear forwards 0.5s; } // Анімація з затримкою 0.5s
  .benefit--2 & { animation: fadeInOutText2 3s linear forwards 0.5s; } // Анімація з затримкою 0.5s
  .benefit--3 & { animation: fadeInOutText3 3s linear forwards 0.5s; } // Анімація з затримкою 0.5s
  .benefit--4 & { animation: fadeInOutText4 3s linear forwards 0.5s; } // Анімація з затримкою 0.5s

  @include breakpoint-min(768px) {
    font-size: 18px;
    line-height: normal;
  }
  @include breakpoint-min(1200px) {
    font-size: 28px;
    line-height: normal;
  }
  @include breakpoint-min(1920px) {
    font-size: 30px;
    line-height: normal;
  }
}

.benefit--1 {
  &.firstCircle::before { transform: rotate(-90deg); }
  &.start::before { transform: rotate(0deg); }
  &.from-benefit-4::before { transform: rotate(360deg); }
  &.from-benefit-4.from-benefit-42::before { transform: rotate(0deg); transition-duration: 0ms; }
}
.benefit--2 {
  &::before { transform: rotate(90deg); }
}
.benefit--3 {
  &::before { transform: rotate(180deg); }
}
.benefit--4 {
  &::before { transform: rotate(270deg); }
}


@keyframes fadeInOut1 {
  0% { transform: translateX(0); }
  16.67% {
    transform: translateX(var(--translateX)); /* Використовуємо змінну для translateX */
    background: linear-gradient(239deg, #2dbc55 24.8%, #155627 134.44%);
  }
  83.33% {
    transform: translateX(var(--translateX)); /* Зберігаємо позицію */
    background: linear-gradient(239deg, #2dbc55 24.8%, #155627 134.44%);
  }
  100% { transform: translateX(0); }
}
@keyframes fadeInOut2 {
  0% { transform: translateX(0); }
  16.67% {
    transform: translateX(var(--translateX)); /* Використовуємо змінну для translateX */
    background: linear-gradient(239deg, #2dbc55 24.8%, #155627 134.44%);
  }
  83.33% {
    transform: translateX(var(--translateX)); /* Зберігаємо позицію */
    background: linear-gradient(239deg, #2dbc55 24.8%, #155627 134.44%);
  }
  100% { transform: translateX(0); }
}
@keyframes fadeInOut3 {
  0% { transform: translateX(0); }
  16.67% {
    transform: translateX(var(--translateX)); /* Використовуємо змінну для translateX */
    background: linear-gradient(239deg, #2dbc55 24.8%, #155627 134.44%);
  }
  83.33% {
    transform: translateX(var(--translateX)); /* Зберігаємо позицію */
    background: linear-gradient(239deg, #2dbc55 24.8%, #155627 134.44%);
  }
  100% { transform: translateX(0); }
}
@keyframes fadeInOut4 {
  0% { transform: translateX(0); }
  16.67% {
    transform: translateX(var(--translateX)); /* Використовуємо змінну для translateX */
    background: linear-gradient(239deg, #2dbc55 24.8%, #155627 134.44%);
  }
  83.33% {
    transform: translateX(var(--translateX)); /* Зберігаємо позицію */
    background: linear-gradient(239deg, #2dbc55 24.8%, #155627 134.44%);
  }
  100% { transform: translateX(0); }
}

@keyframes fadeInOutText1 {
  0% { opacity: 0; }
  16.67% { opacity: 1; }
  83.33%  { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fadeInOutText2 {
  0% { opacity: 0; }
  16.67% { opacity: 1; }
  83.33%  { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fadeInOutText3 {
  0% { opacity: 0; }
  16.67% { opacity: 1; }
  83.33%  { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fadeInOutText4 {
  0% { opacity: 0; }
  16.67% { opacity: 1; }
  83.33%  { opacity: 1; }
  100% { opacity: 0; }
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.input-dropdown {
  @extend %flex-dc;
}
.input-dropdown__checked {
  position: relative;

  min-height: 36px;
  padding: 7px 40px 7px 8px;

  border-radius: 8px;
  border: 1px solid var(--color--neutral, #808080);

  @include breakpoint-min(1200px) {
    min-height: 72px;
    // padding: 14px 68px 14px 16px;
    padding: 14px 60px 14px 16px;

    border-radius: 16px;
  }
  // @include breakpoint-min(1920px) {
  //   min-height: 72px;
  //   padding: 14px 68px 14px 16px;

  //   border-radius: 16px;
  // }
}

.dropdown-checked__list {
  @extend %flex-ac-ww;
  gap: 6px;

  @include breakpoint-min(1200px) {
    gap: 12px;
  }
  // @include breakpoint-min(1920px) {
  //   gap: 12px;
  // }
}
.dropdown-checked__item {
  @extend %flex-ac-jc;
  height: 22px;
  padding: 0 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  border-radius: 4px;
  background: #3f3f3f;

  @include breakpoint-min(1200px) {
    height: 42px;
    padding: 0 16px;

    font-size: 22px;
    line-height: 30px;

    border-radius: 8px;
  }
  // @include breakpoint-min(1920px) {
  //   height: 42px;
  //   padding: 0 16px;

  //   font-size: 22px;
  //   line-height: 30px;

  //   border-radius: 8px;
  // }
}

.input-dropdown__btn {
  position: absolute;
  top: 7px;
  right: 12px;

  width: 22px;
  height: 22px;

  background-image: url('../../../public/assets/images/icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px;
  @extend %transition--100;

  .active & {
    transform: rotate(180deg);
  }

  @include breakpoint-min(1200px) {
    top: 14px;
    right: 16px;

    width: 42px;
    height: 42px;

    background-size: 42px;
  }
  // @include breakpoint-min(1920px) {
  //   top: 14px;
  //   right: 16px;

  //   width: 42px;
  //   height: 42px;

  //   background-size: 42px;
  // }
}

.dropdown-options {
  display: grid;
  grid-template-rows: 0fr;

  @extend %transition--200;

  &.active {
    grid-template-rows: 1fr;
  }
}
.dropdown-options__inner {
  overflow: hidden;
}
.dropdown-options__list {
  @extend %flex-dc;

  border-radius: 8px;
  border: 1px solid var(--color--neutral, #808080);

  @include breakpoint-min(1200px) {
    border-radius: 16px;
  }
  // @include breakpoint-min(1920px) {
  //   border-radius: 16px;
  // }
}
.input--none {
  display: none;
}
.label--checkbox {
  position: relative;

  @extend %flex-ac;
  gap: 6px;
  padding: 4px 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @extend %pointer;

  &::before,
  &::after {
    content: '';
    @extend %flex-ac-jc;
    flex: 0 0 12px;
    width: 12px;
    height: 12px;

    @extend %transition--100;
  }
  &::before {
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid var(--primary, #2dbc55);
  }
  &::after {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);

    background-image: url('../../../public/assets/images/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px;
    opacity: 0;
  }

  input:checked + & {
    &::before {
      background-color: var(--primary, #2dbc55);
    }
    &::after {
      opacity: 1;
    }
  }

  @include breakpoint-min(1200px) {
    gap: 12px;
    padding: 14px 16px;

    font-size: 22px;
    line-height: 30px;

    &::before,
    &::after {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
    }

    &::before {
      border-radius: 6px;
      border-width: 2px;
    }
    &::after {
      left: 16px;

      background-size: 16px;
    }
  }
  // @include breakpoint-min(1920px) {
  //   gap: 12px;
  //   padding: 14px 16px;

  //   font-size: 22px;
  //   line-height: 30px;

  //   &::before,
  //   &::after {
  //     flex: 0 0 24px;
  //     width: 24px;
  //     height: 24px;
  //   }

  //   &::before {
  //     border-radius: 6px;
  //     border-width: 2px;
  //   }
  //   &::after {
  //     left: 16px;

  //     background-size: 16px;
  //   }
  // }
}

:root {
  /* colors */
  --color--white: #fff;
  --color--dark: #000;
  --color--green: #2dbc55;
  --color--error: #e54c4c;
  --color--neutral: #808080;

  // background-color: var(--color--white, #fff);
  // background-color: var(--color--dark, #000);
  // background-color: var(--color--green, #2dbc55);
  // background-color: var(--color--error, #e54c4c);
  // background-color: var(--color--neutral, #808080);

  /* other */
  --items: 1;
  --gap: 8px;
  --transition--20: 20ms linear;
  --transition--100: 100ms linear;
  --transition--200: 200ms linear;
  --transition--300: 300ms linear;
  --transition--400: 400ms linear;
  --transition--500: 500ms linear;
}

// ===== breakpoints =====
$mobileS: 320px;
$mobileSM: 360px;
$mobileM: 375px;
$mobileML: 390px;
$mobileL: 425px;
$mobile: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;
$desktopL: 1440px;
$desktopXL: 1920px;
// ===== breakpoints =====

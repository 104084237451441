@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.projects-category__btn {
  @extend %flex-ac;
  gap: 16px;
  width: 100%;

  color: var(--color--white, #fff);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;

  & svg {
    margin-left: auto;
    @extend %transition--200;
  }

  .active & {
    & svg {
      transform: rotate(180deg);
    }
  }

  @include breakpoint-min(1200px) {
    gap: 58px;

    & svg {
      width: 40px;
      height: 40px;
    }
  }
}
.projects-category__number {
  color: var(--color--green, #2dbc55);
  font-weight: 400;
  line-height: normal;
}
.projects-category__name {
  line-height: 24px;
}

.projects-category__content {
  display: grid;
  grid-template-rows: 0fr;

  opacity: 0;
  @extend %transition--200;

  .projects-category__item.active & {
    grid-template-rows: 1fr;
    opacity: 1;
  }
}
.projects-category__content-inner {
  overflow: hidden;
}

.projects__list {
  @extend %flex-dc;
  gap: 8px;
  margin-top: 12px;

  @include breakpoint-min(1200px) {
    gap: 18px;
    margin-top: 32px;
  }
  // @include breakpoint-min(1920px) {
  //   gap: 18px;
  //   margin-top: 32px;
  // }
}
.projects__item-link {
  @extend %flex-ac;
  gap: 6px;
  padding: 12px;

  color: inherit;
  font-size: 14px;
  line-height: 20px;

  border-radius: 8px;
  background: var(--neutral-500, #181818);
  @extend %transition--200;

  & svg {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
  }

  @include breakpoint-min(1200px) {
    gap: 20px;
    padding: 32px;

    font-size: 36px;
    line-height: 54px;

    border-radius: 20px;

    & svg {
      flex: 0 0 50px;
      width: 50px;
      height: 50px;
    }

    &:hover {
      background: linear-gradient(246deg, #121212 47.14%, #2cb853 252.65%);
    }
  }
}
.projects__item-logo {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;

  object-fit: cover;
  object-position: center;
  background-color: #d9d9d9;
  border-radius: 50%;

  @include breakpoint-min(1200px) {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
  }
}

.projects__link {
  @extend %flex-ac-jc;
  flex-shrink: 0;

  width: 22px;
  height: 22px;
  margin-left: auto;

  & svg {
    flex: 0 0 12px;
    width: 12px;
    height: 12px;
  }

  @include breakpoint-min(1200px) {
    width: 52px;
    height: 52px;

    & svg {
      flex: 0 0 28px;
      width: 28px;
      height: 28px;
    }
  }
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.contacts__list {
  @extend %cardSet;
  --gap: 32px;

  @include breakpoint-min(1200px) {
    flex-direction: row;
  }
  // @include breakpoint-min(1920px) {
  //   flex-direction: row;
  // }
}

.contacts__item {
  @extend %cardSet__item;
  @extend %flex-dc;
  gap: 14px;

  @include breakpoint-min(1200px) {
    --items: 3;
    gap: 18px;
  }
  // @include breakpoint-min(1920px) {
  //   --items: 3;
  //   gap: 18px;
  // }
}

.contacts__title {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    font-size: 22px;
  }
  @include breakpoint-min(1920px) {
    font-size: 28px;
  }
}

.contacts__link {
  color: var(--color--white, #fff);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;

  @include breakpoint-min(1200px) {
    font-size: 26px;
  }
  @include breakpoint-min(1920px) {
    font-size: 32px;
  }
}

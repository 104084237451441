@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.header {
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 1;

  @extend %flex-ac;
  width: 100%;
  --header-height: 54px;
  height: var(--header-height);
  padding-top: 12px;

  color: var(--color--white, #fff);
  // background-color: var(--color--dark, #1e1e1e);

  html.scrollSnap & {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @include breakpoint-min(1200px) {
    padding-top: 30px;
    --header-height: 98px;
  }
  // @include breakpoint-min(1920px) {
  //   padding-top: 30px;
  //   --header-height: 98px;
  // }
}

.header__container {
  @extend %flex-ac;
  --gap: 20px;
  gap: var(--gap);
  height: 42px;

  @include breakpoint-min(1200px) {
    height: 68px;
    --gap: 24px;
  }
  // @include breakpoint-min(1920px) {
  //   height: 68px;
  //   --gap: 24px;
  // }
}

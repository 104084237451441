@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.button {
  position: relative;

  flex-shrink: 0;
  @extend %flex-ac-jc;

  color: var(--color--dark, #000);
  background-color: transparent;
  border: 1px solid transparent;

  @extend %transition--200;
  overflow: hidden;
}
.button--primary {
  height: 48px;
  padding: 12px 24px;
  width: fit-content;

  background-color: var(--color--white, #fff);
  border-color: var(--color--white, #fff);
  border-radius: 24px;
  overflow: hidden;

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
    transform: translateY(-50%);

    width: 101%;
    height: 101%;
    max-width: 101%;
    max-height: 101%;

    background-color: var(--color--white, #fff);
    border-radius: 22px;
    @extend %transition--300;
  }

  & span {
    z-index: 1;
  }

  &:hover {
    color: var(--color--white, #fff);
    // background-color: var(--color--dark, #000);
    background-color: transparent;

    &::before {
      max-width: 0;
      // max-height: 60%;
      max-height: 20%;
      opacity: 0;
      transform: translate(-100%, -50%);
    }
  }


  @include breakpoint-min(1200px) {
    height: 80px;

    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 500px;
  }
  // @include breakpoint-min(1920px) {
  //   height: 80px;

  //   font-size: 22px;
  //   font-style: normal;
  //   font-weight: 500;
  //   line-height: normal;

  //   border-radius: 500px;
  // }
}
.button--transparent-border {
  // width: 28px;
  height: 28px;
  padding: 4px 12px;
  width: fit-content;

  color: var(--color--white, #fff);

  border-color: var(--color--white, #fff);
  border-radius: 24px;
  overflow: hidden;
}

.button--circle {
  width: 28px;
  height: 28px;

  color: #fff;

  border-radius: 50%;
  border: 0.5px solid currentColor;
  background-color: transparent;
  @extend %transition--200;

  & svg {
    fill: currentColor;
    @extend %transition--200;
  }
}

.button--arrow {
  @extend .button--circle;

  & svg {
    width: 12px;
    height: 10px;
  }

  // .active & {
  //   background-color: currentColor;

  //   & svg {
  //     fill: var(--color--dark, #000);
  //   }
  // }
}
.button--arrow-bottom-right {
  @extend .button--arrow;

  & svg {
    transform: rotate(45deg);
  }

  .active & {
    background-color: currentColor;

    & svg {
      fill: var(--color--dark, #000);
      transform: rotate(225deg);
    }
  }
}
.button--arrow-left {
  @extend .button--arrow;

  & svg {
    transform: rotate(180deg);
  }

  &:hover {
    color: var(--color--dark, #000);
    background-color: var(--color--white, #fff);
  }
}
.button--arrow-right {
  @extend .button--arrow;

  &:hover {
    color: var(--color--dark, #000);
    background-color: var(--color--white, #fff);
  }
}

.button--eye {
  @extend .button--circle;

  & svg {
    width: 16px;
    height: 10px;
  }
}

// ===== link =====
.link {
  @extend .button;
}
.link--primary {
  @extend .button--primary;
}
.link--transparent-border {
  @extend .button--transparent-border;
}

.link--eye {
  @extend .button--eye;
}
.link--arrow-left {
  @extend .button--arrow-left;
}
.link--arrow-right {
  @extend .button--arrow-right;
}

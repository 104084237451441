@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.burger-menu__btn {
  @extend %relative;

  @extend %flex-ac-jc;
  width: 30px;
  min-height: 30px;
  height: 100%;

  color: var(--color--white, #fff);
  background-color: transparent;

  body.burgerMenuIsOpen & {
    z-index: 3;
  }

  @include breakpoint-min(1200px) {
    width: 40px;
  }
  // @include breakpoint-min(1920px) {
  //   width: 40px;
  // }
}

.burger-menu__btn .burger-line {
  @extend %absolute;

  width: 24px;
  height: 2px;
  margin: 0;

  border-radius: 8px;
  background-color: currentColor;
  transition-property: height, opacity, transform;
  transition-duration: 0.4s;
  pointer-events: none;

  &:first-child {
    transform: translateY(-3px);
  }
  &:last-child {
    transform: translateY(3px);
  }

  @include breakpoint-min(1200px) {
    width: 32px;
    height: 3.5px;

    &:first-child {
      transform: translateY(-6px);
    }
    &:last-child {
      transform: translateY(6px);
    }
  }
  // @include breakpoint-min(1920px) {
  //   width: 32px;
  //   height: 3.5px;

  //   &:first-child {
  //     transform: translateY(-6px);
  //   }
  //   &:last-child {
  //     transform: translateY(6px);
  //   }
  // }
}

body.burgerMenuIsOpen .burger-menu__btn {
  .burger-line:first-child {
    transform: rotate(-45deg);
  }
  .burger-line:last-child {
    transform: rotate(45deg);
  }
}

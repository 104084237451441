@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.project-page__section {
  padding: 32px 0;
}
.project-page__container.container {
  @extend %flex-ac;
  gap: 10px;
  min-height: 32px;
  margin-bottom: 20px;
}
.project-page__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    font-size: 65px;
    line-height: normal;
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 65px;
  //   line-height: normal;
  // }
}
.project-page__link {
  margin-left: auto;
  height: 100%;
  border: none;

  svg {
    flex: 0 0 22px;
    width: 22px;
    height: 22px;
  }

  @include breakpoint-min(1200px) {
    svg {
      flex: 0 0 60px;
      width: 60px;
      height: 60px;
    }
  }
  // @include breakpoint-min(1920px) {
  //   svg {
  //     flex: 0 0 60px;
  //     width: 60px;
  //     height: 60px;
  //   }
  // }
}

.project {
  padding: 16px 0;

  border-radius: 16px;
  // background: var(--neutral-500, #181818);
  // @extend %transition--300;

  @include breakpoint-min(1200px) {
    padding: 64px 0;

    border-radius: 56px;
  }
  // @include breakpoint-min(1920px) {
  //   padding: 64px 0;

  //   border-radius: 56px;
  // }
}
.project--demo {
  position: relative;

  background: transparent;

  li:not(:last-of-type) &:not(.active) {
    padding-bottom: 0;
  }

  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    border-radius: 16px;
    background: linear-gradient(94deg, #181818 44.36%, #181818 135.15%);
    box-shadow: 0px -5px 9px 0px rgba(0, 0, 0, 0.25);
  }
  li:not(:last-of-type) & {
    &::after {
      height: calc(100% + 60px);
    }
  }

  @include breakpoint-min(1200px) {
    &::after {
      border-radius: 56px;
      transition: 400ms linear;
    }
    li:not(:last-of-type) & {
      &::after {
        height: calc(100% + 100px);
      }
    }
    li:last-of-type &:not(.active) {
      padding-bottom: 32px;
    }

    &:hover {
      &::after {
        background: linear-gradient(94deg, #121212 44.36%, #1f6733 135.15%);
        box-shadow: 0px -12px 24px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }
  // @include breakpoint-min(1920px) {
  //   &::after {
  //     border-radius: 56px;
  //     transition: 400ms linear;
  //   }
  //   li:not(:last-of-type) & {
  //     &::after {
  //       height: calc(100% + 100px);
  //     }
  //   }
  //   li:last-of-type &:not(.active) {
  //     padding-bottom: 32px;
  //   }

  //   &:hover {
  //     &::after {
  //       background: linear-gradient(94deg, #121212 44.36%, #1f6733 135.15%);
  //       box-shadow: 0px -12px 24px 0px rgba(0, 0, 0, 0.25);
  //     }
  //   }
  // }
}
.project--page {
  border-radius: 16px;
  background: var(--neutral-500, #181818);
}

.project__container {
  @extend %flex-dc;
  gap: 16px;

  @include breakpoint-min(1200px) {
    gap: 32px;
  }
  // @include breakpoint-min(1920px) {
  //   gap: 32px;
  // }
}

.project-category {
  @extend %flex-ac;
  gap: 16px;
}
.project-category__number {
  color: var(--color--green, #2dbc55);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    font-size: 52px;
    line-height: normal;
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 52px;
  //   line-height: normal;
  // }
}
.project-category__name {
  color: var(--surface, #fff);

  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
  text-align: start;

  @include breakpoint-min(768px) {
    font-size: 22px;
    line-height: normal;
  }
  @include breakpoint-min(1200px) {
    font-size: 40px;
    // line-height: normal;
  }
  @include breakpoint-min(1920px) {
    font-size: 52px;
    line-height: normal;
  }
}

.project-tags__list {
  @extend %flex-ac-ww;
  gap: 6px;

  @include breakpoint-min(1200px) {
    gap: 18px;
  }
  // @include breakpoint-min(1920px) {
  //   gap: 18px;
  // }
}
.project-tags__item {
  @extend %flex-ac-jc;
  flex-shrink: 0;
  padding: 4px 12px;

  color: var(--neutral-200, #808080);
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  border-radius: 16px;
  background: var(--neutral-400, #222);

  @include breakpoint-min(1200px) {
    padding: 14px 30px;

    font-size: 22px;
    font-weight: 600;

    border-radius: 40px;
  }
  // @include breakpoint-min(1920px) {
  //   padding: 14px 30px;

  //   font-size: 22px;
  //   font-weight: 600;

  //   border-radius: 40px;
  // }
}

.project__content {
  display: grid;
  grid-template-rows: 0fr;

  @extend %transition--200;

  .project.active & {
    grid-template-rows: 1fr;
  }
}
.project__content-inner {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.project__desc {
  @extend %flex-dc;
  gap: 16px;
}
.project__text {
  // font-size: 14px;
  line-height: 20px;
  white-space: pre-line;

  @include breakpoint-min(1200px) {
    font-size: 28px;
    line-height: 40px;
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 28px;
  //   line-height: 40px;
  // }
}

.project__btn--wrap {
  @extend %flex-ac;
  gap: 6px;
  margin-left: auto;

  @include breakpoint-min(1200px) {
    gap: 16px;
  }
  // @include breakpoint-min(1920px) {
  //   gap: 16px;
  // }
}
.project__link {
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    width: 260px;
    // width: 316px;
    font-size: 20px;
  }
  @include breakpoint-min(1920px) {
    width: 316px;
    font-size: 22px;
  }
}
.project__btn {
  @include breakpoint-min(1200px) {
    width: 80px;
    height: 80px;

    & svg {
      width: 42px;
      height: 42px;
    }
  }
  // @include breakpoint-min(1920px) {
  //   width: 80px;
  //   height: 80px;

  //   & svg {
  //     width: 42px;
  //     height: 42px;
  //   }
  // }
}

.project__review-wrap {
  @extend %flex-dc;
  gap: 20px;

  @include breakpoint-min(1200px) {
    gap: 32px;
  }
  // @include breakpoint-min(1920px) {
  //   gap: 32px;
  // }
}
.project__review-title {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    font-size: 65px;
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 65px;
  // }
}
.project__review.review {
  // max-width: 50%;

  border-radius: 10px;
  background: var(--neutral-400, #222);

  @include breakpoint-min(768px) {
    max-width: 50%;
  }
  @include breakpoint-min(1200px) {
    max-width: 524px;
  }
}

.projects-nav.container {
  @extend %flex-ac-jsb;
  gap: 8px;
  width: 100%;
  margin-top: 24px;
  padding-top: 16px;
  padding-bottom: 16px;

  @include breakpoint-min(1200px) {
    gap: 48px;
    padding-top: 42px;
    padding-bottom: 42px;
    margin-top: 16px;
  }
  // @include breakpoint-min(1920px) {
  //   gap: 48px;
  //   padding-top: 42px;
  //   padding-bottom: 42px;
  //   margin-top: 16px;
  // }
}
.projects-nav__link {
  @extend %flex-ac;
  gap: 10px;
  height: 58px;
  width: 100%;
  padding: 8px;

  color: var(--color--white, #fff);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  border-radius: 16px;
  background: var(--neutral-500, #181818);

  &--next {
    justify-content: flex-end;
  }

  & span {
    @extend %flex-ac-jc;
    flex: 0 0 24px;
    width: 24px;
    height: 24px;

    border-radius: 50%;
    border: 1px solid currentColor;
  }
  & svg {
    fill: currentColor;
  }

  &[aria-disabled='true'] {
    pointer-events: none;
    color: rgba($color: #fff, $alpha: 0.5);
  }

  @include breakpoint-min(1200px) {
    gap: 14px;
    height: 144px;
    padding: 32px;

    font-size: 48px;

    & span {
      flex: 0 0 80px;
      width: 80px;
      height: 80px;

      border-width: 2px;
    }

    & svg {
      flex: 0 0 44px;
      width: 44px;
      height: 44px;

      fill: currentColor;
    }
  }
  // @include breakpoint-min(1920px) {
  //   gap: 14px;
  //   height: 144px;
  //   padding: 32px;

  //   font-size: 48px;

  //   & span {
  //     flex: 0 0 80px;
  //     width: 80px;
  //     height: 80px;

  //     border-width: 2px;
  //   }

  //   & svg {
  //     flex: 0 0 44px;
  //     width: 44px;
  //     height: 44px;

  //     fill: currentColor;
  //   }
  // }
}

.project__link--pdf {
  margin-left: auto;
  color: inherit;
  // opacity: 0.8;
  text-decoration: underline;

  @include breakpoint-min(1200px) {
     font-size: 24px;
  }
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  width: 100%;
  @extend %transition--200;

  &.isHidden {
    opacity: 0;
  }

  @include breakpoint-min(1200px) {
    top: 48px;
  }
  // @include breakpoint-min(1920px) {
  //   top: 48px;
  // }
}

.notification__container {
  @extend %flex-jc;
}

.notification__box {
  position: relative;

  @extend %flex-ac-dc;
  padding: 12px 16px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid transparent;
  background: var(--neutral-500, #181818);

  .success & {
    border-color: var(--secondary, #1b6a31);
  }
  .error & {
    border-color: var(--color--error, #e54c4c);
  }

  @include breakpoint-min(1200px) {
    max-width: 640px;
    padding: 24px 32px;

    border-radius: 24px;
    border-width: 2px;
  }
  // @include breakpoint-min(1920px) {
  //   max-width: 640px;
  //   padding: 24px 32px;

  //   border-radius: 24px;
  //   border-width: 2px;
  // }
}

button.notification__btn-close  {
  position: absolute ;
  top: 8px;
  right: 8px;

  width: 14px;
  height: 14px;

  color: #fff;
  border-radius: initial;

  & svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }

  @include breakpoint-min(1200px) {
    top: 16px;
    right: 16px;

    width: 24px;
    height: 24px;
  }
  // @include breakpoint-min(1920px) {
  //   top: 16px;
  //   right: 16px;

  //   width: 24px;
  //   height: 24px;
  // }
}

.notification__text {
  text-align: center;
  font-size: 14px;
  line-height: 20px;

  @include breakpoint-min(1200px) {
    font-size: 28px;
    line-height: 36px;
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 28px;
  //   line-height: 36px;
  // }
}

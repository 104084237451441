@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.reviews {
  padding: 42px 0 28px;
}

.reviews__container {
  position: relative;

  max-height: calc(var(--window-height, 100vh) - 70px);
  overflow: hidden;

  &::before {
    content: '';

    position: absolute;
    bottom: -80px;
    left: calc((100vw - 100%) / 2 * -1);
    z-index: 1;

    width: 100vw;
    height: 214px;

    background: linear-gradient(0deg, #000 50%, rgba(0, 0, 0, 0) 78.5%);
  }
}

.reviews__title {
  margin-bottom: 18px;

  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @include breakpoint-min(768px) {
    font-size: 24px;
  }
  @include breakpoint-min(1200px) {
    font-size: 40px;
  }
  @include breakpoint-min(1920px) {
    font-size: 44px;
  }
}

.reviews__list-wrap {
  @extend %cardSet;
  --gap: 24px;

  @include breakpoint-min(1920px) {
    --gap: 48px;
  }
}

.reviews__list {
  @extend %cardSet__item;
  @extend %flex-dc;
  gap: var(--gap);

  @include breakpoint-min(768px) {
    --items: 2;
  }
  // @include breakpoint-min(1200px) {
  //   --items: 3;
  // }
  @include breakpoint-min(1920px) {
    --items: 3;
  }
}

.reviews__link {
  position: absolute;
  // bottom: 42px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  width: 100%;
  max-width: 210px;
  // width: fit-content;
  margin: 20px auto;

  font-weight: 500;
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    max-width: 330px;
  }
  // @include breakpoint-min(1920px) {
  //   max-width: 330px;
  // }
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.order-btn__wrap.container {
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 1;

  @extend %flex-ac;
  justify-content: flex-end;
  padding-bottom: 16px;

  pointer-events: none;

  @include breakpoint-min(768px) {
    padding-bottom: 20px;
  }
}

.order-btn.button--primary {
  @extend %flex-ac;
  gap: 12px;
  width: 280px;
  height: 80px;

  font-size: 18px;
  color: var(--color--dark, #000);
  pointer-events: initial;
  transition: 100ms linear;

  & svg {
    z-index: 1;
    stroke: currentColor;
  }

  &:not(.isVisible) {
    opacity: 0;
    pointer-events: none;
    visibility: none;
  }

  &:hover {
    color: #fff;
  }

  @include breakpoint-max(1200px - 1px) {
    width: 56px;
    height: 56px;
    padding: 16px;

    border-radius: 50%;

    span {
      display: none;
    }
    & svg {
      width: 100%;
      height: 100%;
    }
  }

  @include breakpoint-min(1920px) {
    width: 308px;
    height: 104px;

    font-size: 22px;
  }
}
@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.backLink {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  border: 1px solid #fff;

  & svg {
    flex: 0 0 12px;
    width: 12px;
    height: 12px;
  }

  @include breakpoint-min(1200px) {
    flex: 0 0 60px;
    width: 60px;
    height: 60px;

    border: 2px solid #fff;

    & svg {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
    }

    // & svg {
    //   flex: 0 0 60px;
    //   width: 60px;
    //   height: 60px;
    // }
  }
  // @include breakpoint-min(1920px) {
  //   flex: 0 0 60px;
  //   width: 60px;
  //   height: 60px;

  //   border: 2px solid #fff;

  //   & svg {
  //     flex: 0 0 24px;
  //     width: 24px;
  //     height: 24px;
  //   }

  //   // & svg {
  //   //   flex: 0 0 60px;
  //   //   width: 60px;
  //   //   height: 60px;
  //   // }
  // }
}

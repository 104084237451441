@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.feedback-form {
  @extend %flex-dc;
  gap: 18px;
  width: 100%;
  padding: 24px;
  margin: auto;
  max-width: 678px;

  border-radius: 16px;
  background: var(--neutral-500, #181818);

  @include breakpoint-min(1200px) {
    gap: 32px;
    padding: 48px;
  }
  // @include breakpoint-min(1920px) {
  //   gap: 32px;
  //   padding: 48px;
  // }
}
.feedback-form__header {
  position: relative;

  @extend %flex-ac-jc;
}
.feedback-form__title {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    font-size: 28px;
    line-height: 140%;
  }
  // @include breakpoint-min(1920px) {
  //   font-size: 28px;
  //   line-height: 140%;
  // }
}
.feedback-form__btn-close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  width: 16px;
  height: 16px;
  padding: 2px;

  & svg {
    width: 100%;
    height: 100%;
  }

  @include breakpoint-min(1200px) {
    width: 32px;
    height: 32px;

    padding: 4px;
  }
  // @include breakpoint-min(1920px) {
  //   width: 32px;
  //   height: 32px;

  //   padding: 4px;
  // }
}

.feedback-form__body {
  @extend %flex-dc;
  gap: 12px;

  @include breakpoint-min(1200px) {
    gap: 24px;
  }
  // @include breakpoint-min(1920px) {
  //   gap: 24px;
  // }
}

.feedback-for__label-wrap {
  position: relative;

  @extend %flex-dc;
  @extend %transition--100;

  &::after {
    content: attr(data-error);

    display: none;
    margin-top: 4px;

    color: var(--color--error, #e54c4c);
    font-size: 10px;
    font-style: normal;
    line-height: normal;
  }
  &.error::after {
    display: inline-block;
  }

  @include breakpoint-min(1200px) {
    &::after {
      margin-top: 6px;

      font-size: 16px;
      line-height: 22px;
    }
  }
  // @include breakpoint-min(1920px) {
  //   &::after {
  //     margin-top: 6px;

  //     font-size: 16px;
  //     line-height: 22px;
  //   }
  // }
}
.feedback-for__label {
  margin-bottom: 10px;

  color: var(--surface, #fff);
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    margin-bottom: 18px;

    font-size: 22px;
  }
  // @include breakpoint-min(1920px) {
  //   margin-bottom: 18px;

  //   font-size: 22px;
  // }
}
.feedback-for__input {
  @extend %flex-ac;
  height: 36px;
  padding: 4px 8px;

  color: var(--color--neutral, #808080);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  border-radius: 8px;
  border: 1px solid currentColor;

  &:focus-within {
    color: #fff;
    outline: none;
  }

  &.error {
    color: var(--color--error, #e54c4c);
  }

  @include breakpoint-min(1200px) {
    height: 60px;
    padding: 14px 16px;

    font-size: 22px;
    line-height: 30px;

    border-radius: 16px;
  }
  // @include breakpoint-min(1920px) {
  //   height: 60px;
  //   padding: 14px 16px;

  //   font-size: 22px;
  //   line-height: 30px;

  //   border-radius: 16px;
  // }
}

.feedback-form__btn-submit {
  width: 100%;
  height: 48px;

  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  @include breakpoint-min(1200px) {
    height: 80px;

    font-size: 22px;
    font-weight: 500;

    border-radius: 500px;
  }
  // @include breakpoint-min(1920px) {
  //   height: 80px;

  //   font-size: 22px;
  //   font-weight: 500;

  //   border-radius: 500px;
  // }
}

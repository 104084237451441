@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.marquee {
  @extend %absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
}

.marquee__inner {
  display: flex;
  gap: 16px;
}

$duration: 40s;

.marquee__track {
  flex-shrink: 0;
  @extend %flex-ac;

  overflow: hidden;
  min-width: 100%;

  color: #fff;
  text-align: center;
  font-size: 84px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;

  animation: marquee $duration linear infinite;
  animation-delay: $duration * 2;

  @include breakpoint-min(768px) {
    font-size: 160px;
  }
  @include breakpoint-min(1200px) {
    font-size: 200px;
  }
  @include breakpoint-min(1920px) {
    font-size: 240px;
    // font-size: 280px;
  }
}

.marquee__track--1 {
  animation: marquee $duration linear infinite;
  // background-color: forestgreen;
}
.marquee__track--2 {
  animation: marquee $duration linear infinite;
  // background-color: chocolate;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.loader--rive {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(4%);
  width: 100%;
  height: 100%;

  background-color: rgba($color: #000000, $alpha: 1);
  opacity: 1;
  z-index: 3000;
  transition: all 500ms linear;

  &::before,
  &::after {
    content: '';

    position: absolute;
    top: 0;

    width: 2px;
    height: 200vh;

    background: linear-gradient(to bottom, #121212, #2cb853 50%, #2cb853 100%);
    transform: translateY(50%);
    animation: moveUp 10s ease-in-out forwards;
  }

  &:before {
    left: 4%;
  }

  &::after {
    right: 4%;
  }

  &.isHidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.loader__rive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  max-width: 360px;
  max-height: 360px;
}

@keyframes moveUp {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(-50%);
  }
}

@import '/src/scss/utils/placeholders';
@import '/src/scss/utils/mixins.scss';

.services {
  position: relative;
  // padding: 48px 0 234px;

  @extend %flex-dc;
  padding: 12px 0;

  &::before, &::after {
    content: '';

    position: absolute;
    left: 0;

    display: block;
    width: 100%;

    --deg: 0deg;

    background: linear-gradient(var(--deg), rgba(0, 0, 0, 0) 0%, #000 100%);
  }
  &::before {
    top: -4px;
    z-index: -1;

    // height: 400px;
    height: 100%;
    --deg: 0deg;
  }
  &::after {
    bottom: -4px;
    z-index: -2;

    height: 360px; 
    // height: 100px;
    --deg: 180deg;
  }

  @include breakpoint-min(768px) {
    padding: 20px 0;
  }

  @include breakpoint-min(1200px) {
    padding: 40px 0;
    // &::before { height: 100%; }
    // &::after { height: 360px; }
    // padding: calc(78px + var(--header-height)) 0 220px;
  }
  @include breakpoint-min(1920px) {
    padding: 64px 0;
    // &::before { height: 100%; }
    // &::after { height: 360px; }
    // padding: calc(78px + var(--header-height)) 0 220px;
  }
}

.services__container.container  {
  position: relative;
  @extend %flex-ac;
  flex-grow: 1;
}

.services__title {
  position: absolute;
  top: 0;
  right: 16px;

  margin-bottom: 88px;

  color: var(--neutral-400, #222);
  text-align: right;

  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @include breakpoint-min(768px) {
    right: 32px;

    font-size: 22px;
  }
  @include breakpoint-min(1200px) {
    right: 80px;

    font-size: 36px;
  }
  @include breakpoint-min(1920px) {
    right: 120px;

    margin-bottom: 120px;
    font-size: 42px;
  }

  // @include breakpoint-min(1200px) {
  //   margin-bottom: 120px;

  //   font-size: 48px;
  //   font-weight: 700;
  // }
  // @include breakpoint-min(1920px) {
  //   margin-bottom: 80px;

  //   font-size: 48px;
  //   font-weight: 700;
  // }
}

.services__list {
  @extend %flex-ww;
  gap: 24px;

  // max-width: 1400px;
  // max-width: 1368px;

  @include breakpoint-min(1920px) {
    max-width: 1500px;
  }
}

.service {
  position: relative;

  // @extend %flex-ac;
  gap: 12px;
  width: 100%;
  // width: fit-content;
  padding-right: 54px;

  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-transform: uppercase;

  transition: 600ms ease;

  & span {
    margin: 5px 0 auto 12px;
    font-size: 16px;
    line-height: 1;
  }

  &::before {
    content: attr(data-number);

    position: absolute;
    top: 6px;
    right: 22px;

    width: 20px;

    font-size: 16px;
    line-height: 1;
    // height: 20px;
  }

  &:not(:last-of-type) {
    &::after {
      content: '/';

      position: absolute;
      top: 0px;
      right: 0;

      width: 10px;
      // margin-left: 6px;
      
      color: #fff;

      transform: scaleX(-1);
    }
  }

  @include breakpoint-min(768px) {
    width: fit-content;
  }

  @include breakpoint-min(1200px) {
    // gap: 20px;
    padding-right: 100px;

    font-size: 48px;
    line-height: normal;

    // & span {
    //   margin: 12px 0 auto;
    //   font-size: 36px;
    //   line-height: 1;
    // }

    &::before {
      right: 40px;

      width: 40px;

      font-size: 36px;
    }
    &:not(:last-of-type) {
      &::after {
        width: 20px;
        font-size: 52px;
      }
    }

    &:hover {
      color: var(--color--green, #2dbc55);
      font-size: 52px;

      &::after {
        color: #fff;
        font-size: 52px;
      }
    }
  }
  @include breakpoint-min(1920px) {
    // gap: 20px;

    font-size: 52px;
    // line-height: normal;

    // & span {
    //   margin: 12px 0 auto;
    //   font-size: 36px;
    //   line-height: 1;
    // }

    &:hover {
      // color: var(--color--green, #2dbc55);
      font-size: 60px;

      &::after {
        // color: #fff;
        font-size: 52px;
      }
    }
  }
}

.service__img {
  position: absolute;
  top: calc((188px + 20px) * -1);
  left: 50%;

  max-width: 342px;
  max-height: 188px;
  object-fit: cover;

  transform: translateX(-50%) rotate(-4deg);
  border-radius: 28px;

  transition: 600ms ease;
  opacity: 0;
  scale: 0;
  pointer-events: none;

  .service:hover & {
    opacity: 1;
    scale: 1;
  }

  @include breakpoint-max(1200px - 1) {
    display: none;
  }
  // @include breakpoint-max(1920px - 1) {
  //   display: none;
  // }
}

.services__video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;

  width: 100%;
  height: 100%;
  object-fit: cover;
}
html {
  scroll-behavior: smooth; /* Плавна прокрутка */
  @extend %transition--200;

  // &.scrollSnap {
  //   scroll-behavior: smooth; /* Плавна прокрутка */
  //   scroll-snap-type: y mandatory; /* Прив'язка до секцій по осі Y */
  //   overflow-y: scroll; /* Дозволяємо вертикальну прокрутку */
  //   height: 100vh; /* Висота контейнера дорівнює висоті вікна */
  // }
  &.freeze,
  &.modalIsOpen,
  &.burgerMenuIsOpen {
    overflow-y: hidden;
  }
}

body {
  font-family: 'Manrope', system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --header-height: 54px;

  min-height: var(--window-height, 100vh);
  font-size: 14px;
  color: var(--color--white, #fff);

  background-color: var(--color--dark, #000);

  // scroll-snap-type: y mandatory; /* Прив'язка до секцій по осі Y */
  // height: 100vh; /* Висота контейнера дорівнює висоті вікна */
  // overflow-y: scroll; /* Дозволяємо вертикальну прокрутку */
  // scroll-behavior: smooth; /* Плавна прокрутка */

  // &.scrollSnap {
  //   scroll-behavior: smooth; /* Плавна прокрутка */
  //   scroll-snap-type: y mandatory; /* Прив'язка до секцій по осі Y */
  //   overflow-y: scroll; /* Дозволяємо вертикальну прокрутку */
  //   height: 100vh; /* Висота контейнера дорівнює висоті вікна */
  // }

  &.freeze,
  &.modalIsOpen,
  &.burgerMenuIsOpen {
    overflow-y: hidden;
  }

  @include breakpoint-min($desktop) {
    --header-height: 98px;
  }
  // @include breakpoint-min($desktopXL) {
  //   --header-height: 98px;
  // }
}
main {
  @extend %relative;

  flex-grow: 1;
  @extend %flex-dc;

  @extend %transition--200;
  contain: paint;
}
.scroll-snap-section {
  // @extend %flex-jc-dc;

  // scroll-snap-align: start; /* Прокручування до початку секції */
  // min-height: var(--window-height, 100vh); /* Висота секцій більше за 100vh */

  html.scrollSnap & {
    @extend %transition--200;
    // scroll-snap-align: start; /* Прокручування до початку секції */
    // min-height: 100vh; /* Висота секцій більше за 100vh */
    min-height: var(--window-height, 100vh); /* Висота секцій більше за 100vh */
  }
}

footer {
  margin-top: auto;

  &.scroll-snap-section {
    min-height: var(--window-height, 100vh); /* Висота секцій більше за 100vh */
  }
}

svg {
  pointer-events: none;
}
button,
a {
  @extend %pointer;
}

.body__inner {
  @extend %flex-dc;
  width: 100%;
  min-height: 100vh;
}

.container {
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;

  --container--width: 100%;
  max-width: var(--container--width);

  @include breakpoint-min($mobileML) {
    // max-width: $mobileML;
    --container--width: 390px;
  }
  @include breakpoint-min($tablet) {
    // max-width: $tablet;
    --container--width: 768px;
    padding: 0 32px;
  }
  // @include breakpoint-min($laptop) {
  //   max-width: $laptop;
  //   padding: 0 60px;
  // }
  @include breakpoint-min($desktop) {
    // max-width: $desktop;
    --container--width: 1200px;
    padding: 0 80px;
  }
  @include breakpoint-min($desktopL) {
    // max-width: $desktopL;
    --container--width: 1440px;
    padding: 0 100px;
  }
  @include breakpoint-min($desktopXL) {
    // max-width: $desktopXL;
    --container--width: 1920px;
    padding: 0 120px;
  }
}

.backdrop {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;

  background-color: transparent;
  @extend %transition--300;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .isHidden & {
    opacity: 0;
  }
}
.swiper {
  overflow: hidden;
}

button.center {
  background-color: orange !important;
}

// video.video--bg {
//   html.isIPhone & {
//     display: none;
//   }
// }